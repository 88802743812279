import Vue from 'vue'
import Vuex from 'vuex' 
import axios from 'axios'
import router from "@/router"
import carts from './modules/carts'
import actionsCards from './modules/actionsCarts'
import actionsProject from './modules/actionsProject'
import project from './modules/project'
import public_ from './modules/public_'
import report from './modules/report'
import accounts from './modules/accounts'
import guess from './modules/guess'
import cards_name from './modules/cards_name'
import safes from './modules/safes'

// axios.defaults.baseURL = "https://new.joodaccounting.com/ccmsapi/public/api"
axios.defaults.baseURL = "https://ali.joodaccounting.com/ccmsapi/public/api"
// axios.defaults.baseURL = "http://localhost:/CCMS_api/public/api"
// axios.defaults.baseURL = "http://192.168.123/CCMS_api/public/api"
// axios.defaults.baseURL = "https://ali.joodaccounting.com/ccmsapi/public/api"
// axios.defaults.baseURL = "https://joodaccounting.com/ccmsapi/public/api"


axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem("token")}`;




Vue.use(Vuex)

export default new Vuex.Store({
    state: { 
        user: []
    },
    getters: {
        token: (state) => (state.user.token),
        person: (state) => (state.user.person),
    },
    mutations: {
        setUser(state, data) { 
            state.user = data
        }
    },
    actions: {
        logout({
            commit
        }) {
            axios.get('a/user/logout')
                .then(() => {
                    localStorage.removeItem('token')
                    localStorage.removeItem('image')
                    localStorage.removeItem('name')
                    localStorage.removeItem('permissions') 

                    localStorage.removeItem('id')
                    commit('setUser', {})
                    router.push('/login')
                })
                .catch(err => {
                    if (err.response.status == 401) {
                        localStorage.removeItem('token')
                        localStorage.removeItem('image')
                        localStorage.removeItem('name')
                        localStorage.removeItem('permissions') 
                        localStorage.removeItem('id') 
                        router.push('/login')
                    }
                    console.error(err);
                })
        },
        setUserFromLocal({
            commit
        }) {
            let user = {}
            user.token = localStorage.getItem('token')
            user.person = {}
            user.person.name = localStorage.getItem('name')
            user.person.image = localStorage.getItem('image')
            user.person.id = localStorage.getItem('id')
            commit('setUser', user)
        }
    },
    modules: {
        carts,
        actionsCards,
        project,
        actionsProject,
        public_,
        accounts,
        report,
        guess,
        cards_name,
        safes
    }
})