<template>
    <div>
        <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
                <!-- delete dialog -->
                <v-card-title text class="text-h6">هل تريد حذف العنصر بشكل نهائي</v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red darken-1" text @click="dialogDelete=!dialogDelete">الغاء</v-btn>
                    <v-btn color="green darken-1" text @click="deleteItemConfirm">موافق</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-container class="text-center my-5">
            <v-row>
                <v-col>
                    <v-btn to="/addDailyPosition" rounded dark class="mx-3 my_btn elevation-0 bg-main green--text">
                        <v-icon>mdi-file-document-outline</v-icon>أضافة موقف يومي
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>
        <v-container  >
            <v-dialog v-model="dialogSorting" transition="dialog-transition">
                <v-card>
                    <v-card-title primary-title> البحث حسب </v-card-title>
                    <v-card-text>
                        <v-container fluid>
                            <v-row>
                                <v-col cols="12" sm="6" md="4">
                                    <v-text-field outlined v-model="searchExpenses.title" required label="الفقرة" reverse>
                                    </v-text-field>
                                </v-col>
                                <v-col dir="ltr" cols="12" sm="6" md="4">
                                    <v-autocomplete reverse outlined v-model="searchExpenses.project_id" multiple :items="projects_n" required item-text="name" item-value="id" label="اختر المشروع "></v-autocomplete>
                                </v-col>
                                <v-col dir="ltr" cols="12" sm="6" md="4">
                                    <v-autocomplete small outlined auto-select-first reverse v-model="searchExpenses.payment_method" :items="cash" item-text="name" item-value="id" label="نوع الدفع"></v-autocomplete>
                                </v-col>
    
                                <v-col dir="ltr" cols="12" sm="6" md="4">
                                    <v-autocomplete reverse outlined v-model="searchExpenses.construction_category_id" multiple :items="constructions" item-text="name" item-value="id" label="مرحلة العمل"></v-autocomplete>
                                </v-col>
                                <v-col dir="ltr" cols="12" sm="6" md="4">
                                    <v-autocomplete reverse outlined v-model="searchExpenses.expense_type_id" multiple :items="expensesType" item-text="name" item-value="id" label="التبويب"></v-autocomplete>
                                </v-col>
                                <v-col dir="ltr" cols="12" sm="6" md="4">
                                    <v-autocomplete reverse outlined v-model="searchExpenses.user_id" multiple :items="users_name" item-text="name" item-value="id" label="مستخدم"></v-autocomplete>
                                </v-col>
                                <v-col dir="ltr" cols="12" sm="6" md="4">
                                    <v-autocomplete reverse outlined v-model="searchExpenses.weather_id" :items="weather" item-text="title" item-value="id" label="الطقس"></v-autocomplete>
                                </v-col>
                                <v-col cols="12" sm="6" md="4">
                                    <v-text-field reverse outlined v-model="searchExpenses.from" label=" من تاريخ" type="date"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="4">
                                    <v-text-field reverse outlined v-model="searchExpenses.to" label="الى تاريخ" type="date"></v-text-field>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn @click="dialogSorting = !dialogSorting" dark color="red">الغاء</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn class="mx-2" @click="searchExpenses={}" color="blue">تفريغ</v-btn>
                        <v-btn @click="searchEx()" color="success">بحث</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
    
            <!-- {{ dailyPosition }} -->
            <v-data-table :headers="headers" show-expand :items="dailyPosition.data" class="my_btn elevation-0 bg-main"  hide-default-footer>
                <template v-slot:top>
                    <v-toolbar flat class="bg-main">
                        <!-- header -->
                        <v-toolbar-title>الموقف اليومي</v-toolbar-title>
                        <v-divider class="mx-4" inset vertical></v-divider>
                        <v-btn color="success" small class="my_btn" @click="dialogSorting = !dialogSorting">
                            <v-icon>mdi-sort-bool-ascending-variant</v-icon> البحث
                            التفصيلي
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn rounded color="main" class="elevation-0 mb-2 mx-2 my_btn" @click="print()">
                            <v-icon color="blue">mdi-printer-outline</v-icon>
                        </v-btn>
                    </v-toolbar>
                </template>>
    
                <!-- more detials -->
                <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length">
                        تم الادخال بواسطة:
                        <b class="mx-1"> {{ item.user.name }} </b>
                          تاريخ و وقت الادخال:
                        <b class="mx-1">{{ item.created_at  }}</b>
                        <span v-if="item.measurement">
                            الكمية: <b class="mx-1">{{ item.measurement.count }}</b> سعر
                            الوحده:
                            <b class="mx-1">{{ item.measurement.unit_price }}</b> الطول:
                            <b class="mx-1">{{ item.measurement.length }}</b> العرض:
                            <b class="mx-1">{{ item.measurement.width }}</b> الارتفاع:
                            <b class="mx-1">{{ item.measurement.height }}</b>
                        </span>الطقس:
                        <b v-if="item.weather" class="mx-1">{{ item.weather.title }}</b>
                    </td>
                </template>
    
                <!-- actions of table -->
                <template v-slot:[`item.operation`]="{item}">
                    <v-btn class="" :to="'addDailyPosition?id='+item.id" icon>
                        <v-icon>
                            mdi-pencil
                        </v-icon>
                    </v-btn>
                    <v-btn @click="deleteItem(item)" icon>
                        <v-icon color="red"> mdi-delete </v-icon>
                    </v-btn>
                </template>
                <!-- time -->
                <!-- <template v-slot:[`item.created_at`]="{ item }">
                    <div></div>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on">{{ item.created_at[0] }}</span>
                        </template>
                        <span>{{ item.created_at[1].slice(0, 10) }}</span>
                    </v-tooltip>
                </template> -->
                <!-- type  -->
                <template v-slot:[`item.is_cash`]="{ item }">
                    <v-chip small color="red" text-color="white" v-if="!item.is_cash">آجل</v-chip>
                    <v-chip small color="cyan" text-color="white" v-if="item.is_cash">نقد</v-chip>
                </template>
                <!-- actions of table -->
                <template v-slot:no-data> لا توجد بيانات </template>
                <template v-slot:footer>
                <v-pagination
                        class="ma-3" 
                        prev-icon="mdi-chevron-right"
                        next-icon="mdi-chevron-left"
                        v-model="pagination.current_page"
                       :length="pagination.last_page"
                    @input="event=> changePage(event)"
    
                ></v-pagination>
            </template>
            </v-data-table>
            <v-row class="my-5 text-center">
                <v-col cols="12" md="4" class="elevation-0">
                    <v-alert type="success" class="my_btn" border="right">
                        المبلغ الكلي : {{dailyPosition.reports.sum_amounts}}
                    </v-alert>
                </v-col>
            </v-row>
            <v-dialog v-model="dialogPrintSet" max-width="900px">
                <v-card>
                    <v-card-title text class="text-h6">اختر الاعمده للطباعة </v-card-title>
                    <v-row class="mx-5">
                        <v-col class="mx-5" dir="ltr" cols="12" md="6">
                            <v-combobox reverse outlined v-model="headersPrint" :items="headers" multiple item-text="text" item-value="value" label="اختر العمود"></v-combobox>
                        </v-col>
                        <v-col class="mx-5" dir="ltr" cols="12" md="6">
                            <v-combobox reverse outlined v-model="printingArray" :items="printingArrayChoies" multiple item-text="name" item-value="text" label="اختر باقي التفاصيل"></v-combobox>
                        </v-col>
                    </v-row>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="red darken-1" text @click="dialogPrintSet=!dialogPrintSet">الغاء</v-btn>
                        <v-btn rounded :loading="btnLoad" color="main" class="elevation-0 mb-2 mx-2  " @click="printOk()">
                            طباعة
                            <v-icon color="blue">mdi-printer-outline</v-icon>
                        </v-btn>
                        <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog v-model="dialogPrint">
                <v-card>
                    <v-btn @click="dialogPrint=!dialogPrint" large color="error">اغلاق</v-btn>
                    <h3 id="p_title" class="p_center"> الموقف اليومي </h3>
                    <v-data-table id="table" :headers="headersPrint" :items="dailyPositionPrint.data" class="elevation-1" hide-default-footer disable-pagination>
                        <template v-slot:[`item.is_complete`]="{ item }">
                            <div v-if="item.is_complete">مكتمل</div>
                            <div v-if="!item.is_complete">غير مكتمل</div>
                        </template>
                        <template v-slot:[`item.is_cash`]="{ item }">
                            <div v-if="item.is_cash">نقد</div>
                            <div v-if="!item.is_cash">أجل</div>
                        </template>
                        <!-- <template v-slot:[`item.created_at`]="{ item }">
                            <span>{{ item.created_at[1].slice(0, 10) }}</span>
                        </template> -->
                        <template v-slot:no-data> لايوجد </template>
                    </v-data-table>
                    <div v-if="dailyPositionPrint.reports" class="p_right" id="footer">
                        <br>
                        <span class="p_box">المبلغ الكلي :{{dailyPositionPrint.reports.sum_amounts}}</span>
                    </div>
                    <div id="geter_reco" class="p_center">
                        <hr>
                        <h3>توقيع المهندس</h3>
                    </div>
                </v-card>
    
            </v-dialog>
        </v-container>
    </div>
    </template>
    
    <script>
    import printing from "@/plugins/print";
    import tools from "@/plugins/tools";
    import axios from "axios";
    import {
        mapActions,
        mapGetters
    } from "vuex";
    export default {
        name: "dailyPosition",
        data() {
            return {
                search: "",
                dialogPrint: false,
                dialogSorting: false,
                dialogPrintSet: false,
                editedIndex: -1,
                dialogDelete: false,
                editedItem: '',
                btnLoad:false,
                pagination:{
                    last_page:null,
                    page:1,   
                },
                dailyPosition:{},
                dailyPositionPrint:{},
                printingArrayChoies: [{
                    text: "p_title",
                    name: 'العنوان'
                }, {
                    text: "table",
                    name: 'الجدول'
                }, {
                    text: "footer",
                    name: 'مجموع المبلغ'
                }, {
                    text: "geter_reco",
                    name: 'توقيع المهندس'
                }],
                printingArray: [{
                    text: "p_title",
                    name: 'العنوان'
                }, {
                    text: "table",
                    name: 'الجدول'
                }, {
                    text: "footer",
                    name: 'مجموع المبلغ'
                }, {
                    text: "geter_reco",
                    name: 'توقيع المهندس'
                }],
                headers: [{
                        text: "المشروع",
                        value: "project.name",
                        align: "start",
                        groupable: false,
                    },
                    {
                        text: "الفقرة",
                        value: "title",
                        align: "start",
                        groupable: false
                    },
                    {
                        text: "الدفع",
                        value: "payment_method",
                        groupable: false
                    },
                    {
                        text: "المتعهد",
                        value: "contractor.name",
                        sortable: false
                    },
                    {
                        text: "التبويب",
                        value: "expenses_type.name"
                    },
                    {
                        text: "مرحلة العمل",
                        value: "construction_category.name",
                        sortable: false,
                    },
                    {
                        text: "تاريخ ",
                        value: "date",
                        groupable: false
                    },
                    {
                        text: "بواسطة",
                        value: "user.name",
                        groupable: false
                    },
                    {
                        text: "المبلغ",
                        value: "total_amount",
                        groupable: false
                    },
                    // {
                    //     text: "الطقس",
                    //     value: "weather.title",
                    //     groupable: false
                    // },
                    {
                        text: "رقم الوصل",
                        value: "out_bill_number",
                        groupable: false
                    },
                    {
                        text: "الملاحضات",
                        value: "notes",
                        groupable: false,
                        sortable: false,
    
                    },
                    {
                        text: "العمليات",
                        value: "operation",
                        groupable: false,
                    },
                ],
                weather: [{
                        id: 1,
                        title: "مشمس",
                    },
                    {
                        id: 2,
                        title: "غائم",
                    },
                    {
                        id: 3,
                        title: "ماطر",
                    },
                    {
                        id: 4,
                        title: "عاصف ",
                    },
                    {
                        id: 5,
                        title: "مثلج ",
                    },
                    {
                        id: 6,
                        title: "ضباضي",
                    },
                ],
                cash: [{
                        id: "1",
                        name: "نقد"
                    },
                    {
                        id: "0",
                        name: "اجل"
                    },
                ],
                headersPrint: [{
                        text: "المشروع",
                        value: "project.name",
                        align: "start",
                        groupable: false,
                        sortable: false,
                    },
                    {
                        text: "الفقرة",
                        value: "title",
                        align: "start",
                        groupable: false,
                        sortable: false,
                    },
                    {
                        text: "الدفع",
                        value: "payment_method",
                        groupable: false,
                        sortable: false,
                    },
                    {
                        text: "المتعهد",
                        value: "contractor.name",
                        sortable: false,
                        groupable: false,
                    },
                    {
                        text: "التبويب",
                        value: "expenses_type.name",
                        sortable: false,
                        groupable: false,
                    },
                    {
                        text: "مرحلة العمل",
                        value: "construction_category.name",
                        sortable: false,
                        groupable: false,
                    },
                    {
                        text: "تاريخ الانشاء",
                        value: "created_at",
                        groupable: false,
                        sortable: false,
                    },
                    {
                        text: "بواسطة",
                        value: "user.name",
                        groupable: false,
                        sortable: false,
                    },
    
                    {
                        text: "المبلغ",
                        value: "total_amount",
                        groupable: false,
                        sortable: false,
                    },
                    {
                        text: "الطقس",
                        value: "weather.title",
                        groupable: false,
                        sortable: false,
                    },
                ],
                searchExpenses: {
                    payment_method: "",
                    is_complete: "",
                    construction_category_id: "",
                    project_id: "",
                    expense_type_id: "",
                    person_id: "",
                    user_id: "",
                    from: "",
                    to: "",
                    weather_id: "",
                },
                searchExpensesDefault: {
                    payment_method: "",
                    is_complete: "",
                    construction_category_id: "",
                    project_id: "",
                    expense_type_id: "",
                    person_id: "",
                    user_id: "",
                    from: "",
                    to: "",
                    weather_id: "",
                },
            };
        },
        computed: mapGetters([ 
            "projects_n",
            "users_name",
            "constructions",
            "expensesType",
        ]),
        created() {
            this.f_constructions();
            this.f_expensesType();
            this.f_projects_n();
            this.f_users_name();
            this.f_dailyPosition(); 
    
        },
        methods: {
            ...mapActions([ 
                'deleteCard',
                "f_users_name",
                "f_projects_n",
                "f_constructions",
                "f_expensesType",
            ]),
            searchEx() {
                this.searchExpenses.page=1
                this.f_dailyPosition(this.searchExpenses);
                this.pagination.last_page=this.dailyPosition.meta.last_page
                this.pagination.page=this.dailyPosition.meta.current_page
                console.log('search:',this.pagination.last_page);
    
                this.dialogSorting = false;
            },
            f_dailyPosition(params){
                // params.item_per_page=10
                axios.post('a/daily-position',params)
                .then(res => {
                    console.log(res.data); 
                    this.dailyPosition=res.data
                    this.pagination=res.data.meta
                })
                .catch(err => {
                    console.error(err); 
                })
            },
            changePage(event){
                this.searchExpenses.page=event
                this.f_dailyPosition(this.searchExpenses)
                this.pagination.last_page=this.dailyPosition.meta.last_page
                this.pagination.page=this.dailyPosition.meta.current_page
            },
            deleteItem(item) {
                tools.checkPermissions('daily-delete')
                this.editedIndex = this.dailyPosition.data.indexOf(item)
                this.editedItem = Object.assign({}, item)
                this.dialogDelete = true
            },
            deleteItemConfirm() {
                let card = [];
                card.type = "daily-position"
                card.id = this.dailyPosition.data[this.editedIndex].id
                this.deleteCard(card)
                this.dialogDelete = false
    
            },
            print() {
                this.dialogPrintSet = true;
            },
            printOk() {
                console.log('q to print: ',this.searchExpenses);
                let params=this.searchExpenses
                params.return_type='print'

                this.btnLoad=true
                axios.post('a/daily-position',params)
                .then(res => {
                    this.dailyPositionPrint=res.data
                    this.dialogPrintSet = false;
                    this.dialogPrint = true;
                    this.btnLoad=false

                    printing(this.printingArray)
                    setTimeout(() => { 
                        this.dialogPrint = false;
                    }, 200)
                })
                .catch(err => {
                    console.error(err); 
                })


               
            }
        },
        // components: { FullCalendar }
    };
    </script>
    